import axios from 'axios';

// Base URL for API requests
const BASE_URL = 'https://server-side-mental.fly.dev/';

// Default Axios instance for public requests
export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }, // Set content type as JSON
    withCredentials: true // Allow sending cookies with cross-origin requests
});

// Axios instance for private or authenticated requests
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }, // Set content type as JSON
    withCredentials: true // Allow sending cookies with cross-origin requests
});
