import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "./useAuth";

const useLogout = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth } = useAuth();

    const logout = async () => {
        try {
            await axiosPrivate.post("/user/logout", {
                username: auth.user,
            });
        } catch (err) {
            //TODO Promise
        } finally {
            await setAuth({});
        }
    };

    return logout;
};

export default useLogout;
