import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useInput from "../hooks/useInput";
import logo from "../images/logo.png";
import arrow from "../icons/left-arrow.svg";

const LOGIN_URL = "/user/login";

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, userAttribs] = useInput("user", "");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL, JSON.stringify({ user, pwd }), {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });
            const { accessToken, roles, userID } = response?.data?.data;
            setAuth({ user, roles, accessToken, userID });
            resetUser();
            setPwd("");
            navigate("/user/home");
        } catch (err) {
            setErrMsg(err.response.data.error);
            errRef.current.focus();
        }
    };

    const handlePwdInput = (e) => setPwd(e.target.value);

    return (
        <div className="log-reg-container">
            <div className="log-reg-top">
                <img className="log-reg-logo" src={logo} alt="logo" />
                <div className="log-reg-div">
                    <form onSubmit={handleSubmit} className="log-reg-form">
                        <Link to="/" className="reg-login-link">
                            <div className="d-flex">
                                <img src={arrow} alt="back arrow" />
                                <span>Home</span>
                            </div>
                        </Link>
                        <h1 className="log-lead">Login</h1>
                        <label htmlFor="username" className="log-reg-label">
                            Username
                        </label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            ref={userRef}
                            value={user}
                            {...userAttribs}
                            className="log-input"
                            autoComplete="on"
                            required
                        />
                        <label htmlFor="password" className="log-reg-label">
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={handlePwdInput}
                            value={pwd}
                            required
                            className="log-input"
                            autoComplete="on"
                        />
                        <button type="submit" className="log-button">Login</button>
                        <p
                            ref={errRef}
                            className={`errorMsg ${errMsg ? "active" : "hidden"}`}
                            aria-live="assertive"
                        >
                            {errMsg}
                        </p>
                    </form>
                    <div className="log-register">
                        <span>Need an account?</span>
                        <Link to="/register" className="log-register-link">
                            Sign Up
                        </Link>
                    </div>
                </div>
            </div>
            <div className="log-reg-bottom"></div>
        </div>
    );
};

export default Login;
