import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import GoBack from "../components/GoBack";

import veryHappy from "../icons/emotions/veryHappy.svg";
import happy from "../icons/emotions/happy.svg";
import neutral from "../icons/emotions/neutral.svg";
import angry from "../icons/emotions/angry.svg";
import sad from "../icons/emotions/sad.svg";
import depressed from "../icons/emotions/depressed.svg";

const AddJournalEntry = () => {
    const axiosPrivate = useAxiosPrivate();

    const [selectedMood, setSelectedMood] = useState("");
    const [selectedEnergyLevel, setSelectedEnergyLevel] = useState("");
    const [selectedSleepLevel, setSelectedSleepLevel] = useState("");
    const [selectedAlcoholIntake, setSelectedAlcoholIntake] = useState("");
    const [selectedLeftHouse, setSelectedLeftHouse] = useState("");
    const [textAreaValue, setTextAreaValue] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const handleOptionChange = (setState) => (e) => {
        e.stopPropagation();
        setState(e.target.value);
    };

    const handleTextAreaChange = (e) => {
        e.stopPropagation();
        setTextAreaValue(e.target.value);
    };

    const addJournalEntry = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.post("/journal/entry/add", {
                mood: selectedMood,
                energy: selectedEnergyLevel,
                sleep: selectedSleepLevel,
                alcohol: selectedAlcoholIntake,
                house: selectedLeftHouse,
                text: textAreaValue,
            });

            if (response.status === 200) {
                setShowPopup(true);
            }
        } catch (err) {}
    };

    return (
        <>
            {showPopup && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">Entry added!</p>
                        <p className="popup-message-small">Well done!</p>
                        <Link to="/journal/home" className="back-to-user-home-btn">
                            Go Back
                        </Link>
                    </div>
                </div>
            )}
            <div>
                <div className="top-controls">
                    <GoBack />
                    <h1 className="main-title">
                        Add <span className="orange-accent">Journal</span> entry
                    </h1>
                </div>
                <div className="new-entry-container">
                    <div className="entry-details">
                        <h2 className="moods-title">I'm feeling...</h2>
                        <div className="moods-container">
                            {[
                                { value: "6", icon: veryHappy },
                                { value: "5", icon: happy },
                                { value: "4", icon: neutral },
                                { value: "3", icon: angry },
                                { value: "2", icon: sad },
                                { value: "1", icon: depressed },
                            ]
                                .reverse() // Reverse the array
                                .map((moodOption) => (
                                    <div key={moodOption.value}>
                                        <input
                                            className="mood-input"
                                            type="radio"
                                            id={`mood-${moodOption.value}`}
                                            name="mood-level"
                                            value={moodOption.value}
                                            onChange={handleOptionChange(setSelectedMood)}
                                        />
                                        <label
                                            className="mood-input-label"
                                            htmlFor={`mood-${moodOption.value}`}
                                        >
                                            <img
                                                className="mood-entry-icon"
                                                src={moodOption.icon}
                                                alt={`mood-${moodOption.value}`}
                                            />
                                        </label>
                                    </div>
                                ))}
                        </div>

                        <div>
                            <h2 className="moods-title">My energy levels</h2>
                            <div className="energy-levels">
                                {[1, 2, 3, 4, 5].map((level) => (
                                    <div key={level}>
                                        <input
                                            className="energy-input"
                                            type="radio"
                                            id={`energy-${level}`}
                                            name="energy-levels"
                                            value={level}
                                            onChange={handleOptionChange(setSelectedEnergyLevel)}
                                        />
                                        <label
                                            className="energy-input-label"
                                            htmlFor={`energy-${level}`}
                                        >
                                            {level}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <h2 className="moods-title">My sleep quality</h2>
                            <div className="sleep-quality">
                                {[1, 2, 3, 4, 5].map((level) => (
                                    <div key={level}>
                                        <input
                                            className="sleep-input"
                                            type="radio"
                                            id={`sleep-${level}`}
                                            name="sleep-levels"
                                            value={level}
                                            onChange={handleOptionChange(setSelectedSleepLevel)}
                                        />
                                        <label
                                            className="sleep-input-label"
                                            htmlFor={`sleep-${level}`}
                                        >
                                            {level}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <h2 className="moods-title">I left the house</h2>
                            <div className="left-house">
                                {["Yes", "No"].map((option) => (
                                    <div key={option}>
                                        <input
                                            className="left-house-input"
                                            type="radio"
                                            id={`house-${option}`}
                                            name="left-house"
                                            value={option === "Yes"}
                                            onChange={handleOptionChange(setSelectedLeftHouse)}
                                        />
                                        <label
                                            className="left-house-label"
                                            htmlFor={`house-${option}`}
                                        >
                                            {option}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <h2 className="moods-title">I consumed alcohol</h2>
                            <div className="alcohol-intake">
                                {["Yes", "No"].map((option) => (
                                    <div key={option}>
                                        <input
                                            className="alcohol-input"
                                            type="radio"
                                            id={`alcohol-${option}`}
                                            name="alcohol-intake"
                                            value={option === "Yes"}
                                            onChange={handleOptionChange(setSelectedAlcoholIntake)}
                                        />
                                        <label
                                            className="alcohol-input-label"
                                            htmlFor={`alcohol-${option}`}
                                        >
                                            {option}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mood-entry-text-container">
                            <h2 className="moods-title">My day</h2>
                            <textarea
                                onChange={handleTextAreaChange}
                                className="mood-entry-text"
                            ></textarea>
                        </div>
                        <button onClick={addJournalEntry} className="save-entry-btn">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddJournalEntry;
