import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const ChatCard = (props) => {
    return (
        <Link to={"/chat/user/" + props.user.ID} className="chat-card-button">
            <div className="chat-card-container">
                <img className="chat-list-img" src={logo} alt="logo" />
                <h1 className="chat-username">{props.user.Name}</h1>
            </div>
        </Link>
    );
};

export default ChatCard;
