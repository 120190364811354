import React from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

const VersusChart = ({ count1, count2, title, label1, label2, description }) => {
    const chartData = {
        labels: [label1, label2],
        datasets: [
            {
                data: [count1, count2],
                backgroundColor: ["#f37925", "#00adb3"],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        indexAxis: 'y', // Set to 'y' for vertical bars
        plugins: {
            legend: {
                display: false, // Remove legend
            },
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: false,
                },
                ticks: {
                    display: false, // Remove number labels
                },
                grid: {
                    display: false, // Remove grid
                },
            },
            y: {
                stacked: true,
                ticks: {
                    display: true, // Remove number labels
                    color: '#e5ddc8',
                    font: {
                        size: 16,
                    }
                },
                grid: {
                    display: false, // Remove grid
                },
            },
        },
    };

    return (
        <div className="versus-chart" title={description}>
            <h2 className="versus-chart-title">{title}</h2>
            <div className="versus-chart-container">
                <Bar
                    data={chartData}
                    options={options}
                />
            </div>
        </div>
    );
};

export default VersusChart;
