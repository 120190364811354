import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import plus from "../icons/plus.svg";
import JournalEntryCard from "../components/JournalEntryCard";
import NoResults from "../components/NoResults";
import GoBack from "../components/GoBack";

const MyJournalEntries = () => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [entries, setEntries] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchJournalEntries = async () => {
            try {
                const response = await axiosPrivate.post("/journal/entries");
                const data = response.data.data;
                setEntries(data);
                setLoading(false);
            } catch (err) {
                handleLoginRedirect();
            }
        };

        fetchJournalEntries();
    }, [axiosPrivate, location]);

    const handleLoginRedirect = () => {
        navigate("/login", { state: { from: location }, replace: true });
    };

    const handleAddEntry = (e) => {
        e.preventDefault();
        navigate("/journal/entry/add");
    };

    return (
        <>
            <div className="journal-title-container">
                <div className="top-controls">
                    <GoBack />
                    <h1 className="main-title">
                        Your <span className="orange-accent">Journal</span>
                    </h1>
                </div>

                <button className="plus-btn add-button" onClick={handleAddEntry}>
                    <img className="menu-icon navbtn" src={plus} alt="add" />
                </button>
            </div>
            {loading ? (
                <Spinner />
            ) : entries.length === 0 ? (
                <NoResults />
            ) : (
                <div className="journal-container">
                    {entries.map((entry, index) => (
                        <JournalEntryCard key={entry.id || index} entry={entry} allowDelete={true} />
                    ))}
                </div>
            )}
        </>
    );
};

export default MyJournalEntries;
