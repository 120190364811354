import React from "react";
import { Link } from "react-router-dom";
import Image from "../components/Image";

const UserCard = (props) => {
  return (
    <Link to={"/search/therapist/" + props.user.Username} className="therapist-card-button">
      <div className="therapist-card-container">
        <h1 className="therapist-name">{props.user.Name}</h1>

        <Image fileName={props.user.ImgPath} />

        <p className="therapist-info">
        {props.user.Description}
        </p>
      </div>
    </Link>
  );
};

export default UserCard;
