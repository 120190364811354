import React from "react";
import logo from "../images/logo.png";
import menu from "../icons/menu.svg";
import useLogout from "../hooks/useLogout";
import { useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";

const Navigation = () => {
    const logout = useLogout();
    const navigate = useNavigate();

    const signOut = async (e) => {
        e.preventDefault();
        await logout();
        navigate("/");
    };

    function navDrop() {
        document.getElementById("nav-drop").classList.toggle("show");
    }

    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
        if (!event.target.matches(".navbtn")) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };

    return (
        <nav className="navigation">
            <div className="nav-banner">
                <img className="logo-nav" src={logo} alt="logo" />
                <span className="nav-logo-text">mental</span>
            </div>
            <button onClick={navDrop} className="dropbtn navbtn">
                <img className="menu-icon navbtn" src={menu} alt="logo" />
            </button>
            <div id="nav-drop" className="dropdown-content">
                <ul className="nav-list">
                    <li>
                        <Link to="/user/home" className="navi-link">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/user/profile" className="navi-link">
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link to="/search/therapists" className="navi-link">
                            Therapist
                        </Link>
                    </li>
                    <li>
                        <Link to="/appointment/calendar" className="navi-link">
                            Book appointment
                        </Link>
                    </li>
                    <li>
                        <Link to="/user/appointments" className="navi-link">
                            Appointments
                        </Link>
                    </li>
                    <li>
                        <Link to="/journal/home" className="navi-link">
                            Journal
                        </Link>
                    </li>
                    <li>
                        <Link to="/chat" className="navi-link">
                            Chat
                        </Link>
                    </li>
                    <li>
                        <button className="navi-link" onClick={signOut}>Logout</button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;
