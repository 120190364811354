import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AppointmentCard from "../components/AppointmentCard";
import Spinner from "../components/Spinner";
import NoResults from "../components/NoResults";
import GoBack from "../components/GoBack";

const Appointments = () => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [appointments, setAppointments] = useState([]);
    const [noResults, setNoResults] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check for empty results when appointments state changes
        setNoResults(appointments.length === 0);
    }, [appointments]);

    useEffect(() => {
        const getAppointments = async () => {
            try {
                const response = await axiosPrivate.post("/user/appointments");
                setAppointments(response.data.data);
            } catch (err) {
                navigate("/login", { state: { from: location }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        getAppointments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Removed eslint-disable-next-line for exhaustive-deps warning

    return (
        <div>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    Your <span className="orange-accent">booked</span> appointments
                </h1>
            </div>
            {loading ? (
                <Spinner />
            ) : noResults ? (
                <NoResults />
            ) : (
                <div className="appointment-cards-container">
                    {appointments.map((appointment) => (
                        <AppointmentCard key={appointment.ID} appointment={appointment} allowCancel={true}/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Appointments;
