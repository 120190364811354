import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../icons/arrow-left-circle.svg";

const GoBack = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // This navigates back one step in the history
    };

    return (
        <button className="go-back-bttn" onClick={goBack}>
            <img src={ArrowLeft} alt="back arrow" className="go-back-arrow" />
        </button>
    );
};

export default GoBack;
