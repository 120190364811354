import React from "react";

import veryHappy from "../icons/emotions/veryHappy.svg";
import happy from "../icons/emotions/happy.svg";
import neutral from "../icons/emotions/neutral.svg";
import angry from "../icons/emotions/angry.svg";
import sad from "../icons/emotions/sad.svg";
import depressed from "../icons/emotions/depressed.svg";

const MonthlyChart = (props) => {
    const generateTableRow = (record, index) => {
        let moodImage, colour;

        switch (record.Mood) {
            case 1:
                moodImage = depressed;
                colour="#58355E";
                break;
            case 2:
                moodImage = sad;
                colour="#2D7DD2";
                break;
            case 3:
                moodImage = angry;
                colour="#F4442E";
                break;
            case 4:
                moodImage = neutral;
                colour="#FFFFFF";
                break;
            case 5:
                moodImage = happy;
                colour="#5DA271";
                break;
            case 6:
                moodImage = veryHappy;
                colour="#efff40";
                break;
            default:
                break;
        }

        return (
            <tr key={index}>
                <th scope="row">
                    <img className="mood-icon-chart" src={moodImage} alt="Mood" />
                </th>
                <td className={colour} style={{ "--start": record.Start, "--end": record.End, "--color": colour}}></td>
            </tr>
        );
    };

    return (
        <div className="monthly-chart-container">
            <div id="monthly-chart">
                <h2 className="chart-title">Mood changes</h2>
                <table className="charts-css line hide-data show-labels show-primary-axis">
                    <tbody>{props.records.map((record, index) => generateTableRow(record, index))}</tbody>
                </table>
            </div>
        </div>
    );
};

export default MonthlyChart;
