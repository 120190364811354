import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import GoBack from "../components/GoBack";
import Image from "../components/Image";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;

const MyProfile = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPasswordPopup, setShowPasswordPopup] = useState(false);
    const [showNamePopup, setShowNamePopup] = useState(false);
    const [showRemoveTherapistPopup, setShowRemoveTherapistPopup] = useState(false);
    const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);
    const [showPicturePopup, setShowPicturePopup] = useState(false);
    const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
    const [oldPwd, setOldPwd] = useState("");
    const [newPwd, setNewPwd] = useState("");
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newDetails, setNewDetails] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axiosPrivate.post("/user/profile");

                setUser(response.data.data);
            } catch (err) {
                navigate("/login", { state: { from: location }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        getUser();
        // eslint-disable-next-line
    }, []);

    const uploadProfilePicture = async () => {
        try {
            const formData = new FormData();
            formData.append("profilePicture", profilePicture);

            const response = await axiosPrivate.put("/user/edit/profile-picture", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                setShowPicturePopup(false);
                window.location.reload();
            }
        } catch (error) {
            console.error("Error uploading profile picture:", error);
        }
    };

    const showEditPassword = (e) => {
        e.preventDefault();
        setShowPasswordPopup(true);
    };

    const hideEditPassword = (e) => {
        e.preventDefault();
        setShowPasswordPopup(false);
    };

    const editPassword = async (e) => {
        e.preventDefault();
        const test = PWD_REGEX.test(newPwd);

        if (!test) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            const response = await axiosPrivate.put("/user/edit/password", {
                oldPassword: oldPwd,
                newPassword: newPwd,
            });

            if (response.status === 200) {
                setShowPasswordPopup(false);
                window.location.reload();
            }
        } catch (err) {}
    };

    const showEditName = (e) => {
        e.preventDefault();
        setShowNamePopup(true);
    };

    const hideEditName = (e) => {
        e.preventDefault();
        setShowNamePopup(false);
    };

    const editName = async (e) => {
        e.preventDefault();
        const test = USER_REGEX.test(newName);

        if (!test) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            const response = await axiosPrivate.put("/user/edit/name", {
                newName: newName,
            });

            if (response.status === 200) {
                setShowNamePopup(false);
                window.location.reload();
            }
        } catch (err) {}
    };

    const showEditDescription = (e) => {
        e.preventDefault();
        setShowDescriptionPopup(true);
    };

    const hideEditDescription = (e) => {
        e.preventDefault();
        setShowDescriptionPopup(false);
    };

    const editDescription = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.put("/user/edit/description", {
                newDescription: newDescription,
            });

            if (response.status === 200) {
                setShowDescriptionPopup(false);
                window.location.reload();
            }
        } catch (err) {}
    };

    const showEditDetails = (e) => {
        e.preventDefault();
        setShowDetailsPopup(true);
    };

    const showEditPicture = (e) => {
        e.preventDefault();
        setShowPicturePopup(true);
    };

    const hideEditDetails = (e) => {
        e.preventDefault();
        setShowDetailsPopup(false);
    };

    const editDetails = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.put("/user/edit/details", {
                newDetails: newDetails,
            });

            if (response.status === 200) {
                setShowDetailsPopup(false);
                window.location.reload();
            }
        } catch (err) {}
    };

    const showRemoveTherapist = (e) => {
        e.preventDefault();
        setShowRemoveTherapistPopup(true);
    };

    const hideRemoveTherapist = (e) => {
        e.preventDefault();
        setShowRemoveTherapistPopup(false);
    };

    const hideEditProfilePicture = (e) => {
        e.preventDefault();
        setShowPicturePopup(false);
    };

    const removeTherapist = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.put("/user/edit/remove/therapist");

            if (response.status === 200) {
                setShowRemoveTherapistPopup(false);
                window.location.reload();
            }
        } catch (err) {}
    };

    const showDeleteAccount = (e) => {
        e.preventDefault();
        setShowDeleteAccountPopup(true);
    };

    const hideDeleteAccount = (e) => {
        e.preventDefault();
        setShowDeleteAccountPopup(false);
    };

    const deleteAccount = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.delete("/user/edit/delete/" + user.Username);

            if (response.status === 200) {
                setShowNamePopup(false);
                navigate("/");
            }
        } catch (err) {}
    };

    const loadTherapistView = (user) => {
        return (
            <>
                <div className="my-profile-section">
                    <Image fileName={user.ImgPath} />
                    <button onClick={showEditPicture} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Username</h1>
                    <h2 className="mp-subheading">{user.Username}</h2>
                    <button className="mp-button disabled" disabled>
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Password</h1>
                    <h2 className="mp-subheading">********</h2>
                    <button onClick={showEditPassword} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Name</h1>
                    <h2 className="mp-subheading">{user.Name}</h2>
                    <button onClick={showEditName} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Description</h1>
                    <h2 className="mp-subheading">{user.Description}</h2>
                    <button onClick={showEditDescription} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Details</h1>
                    <h2 className="mp-subheading">{user.Details}</h2>
                    <button onClick={showEditDetails} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Therapist</h1>
                    <h2 className="mp-subheading">
                        {user?.Therapist?.Name ? user.Therapist.Name : " "}
                    </h2>
                    <button onClick={showRemoveTherapist} className="mp-button">
                        Remove
                    </button>
                </div>

                <button onClick={showDeleteAccount} className="mp-button-delete">
                    Delete account
                </button>
            </>
        );
    };

    const loadPatientView = (user) => {
        return (
            <>
                <div className="my-profile-section">
                    <Image fileName={user.ImgPath} />
                    <button onClick={showEditPicture} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Username</h1>
                    <h2 className="mp-subheading">{user.Username}</h2>
                    <button className="mp-button disabled" disabled>
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Password</h1>
                    <h2 className="mp-subheading">********</h2>
                    <button onClick={showEditPassword} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Name</h1>
                    <h2 className="mp-subheading">{user.Name}</h2>
                    <button onClick={showEditName} className="mp-button">
                        Edit
                    </button>
                </div>
                <div className="my-profile-section">
                    <h1 className="mp-heding">Therapist</h1>
                    <h2 className="mp-subheading">
                        {user?.Therapist?.Name ? user.Therapist.Name : " "}
                    </h2>
                    <button onClick={showRemoveTherapist} className="mp-button">
                        Remove
                    </button>
                </div>

                <button onClick={showDeleteAccount} className="mp-button-delete">
                    Delete account
                </button>
            </>
        );
    };

    const passwordPopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Edit password</h1>
                    <p>
                        8 to 24 characters. Must include uppercase and lowercase letters, a number
                        and a special character. Allowed special characters:{" "}
                        <span aria-label="exclamation mark">!</span>{" "}
                        <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>{" "}
                        <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </p>
                    <label htmlFor="password" className="log-reg-label">
                        Old password
                    </label>
                    <input
                        type="password"
                        name="password"
                        className="reg-input"
                        onChange={(e) => setOldPwd(e.target.value)}
                    ></input>
                    <label htmlFor="confirm_password" className="log-reg-label">
                        New Password
                    </label>
                    <input
                        type="password"
                        name="new_password"
                        className="reg-input"
                        onChange={(e) => setNewPwd(e.target.value)}
                    ></input>
                    <div>
                        <button onClick={hideEditPassword} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={editPassword} className="save-btn">
                            Save
                        </button>
                    </div>
                    <p className={`errorMsg ${errMsg ? "active" : "hidden"}`} aria-live="assertive">
                        {errMsg}
                    </p>
                </div>
            </div>
        );
    };

    const namePopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Edit name</h1>
                    <p>
                        4 to 24 characters. Must begin with a letter. Letters, numbers, underscores,
                        hyphens allowed.
                    </p>
                    <label htmlFor="name" className="log-reg-label">
                        New name
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="reg-input"
                        onChange={(e) => setNewName(e.target.value)}
                    ></input>
                    <div>
                        <button onClick={hideEditName} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={editName} className="save-btn">
                            Save
                        </button>
                    </div>
                    <p className={`errorMsg ${errMsg ? "active" : "hidden"}`} aria-live="assertive">
                        {errMsg}
                    </p>
                </div>
            </div>
        );
    };

    const descriptionPopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Edit description</h1>
                    <label htmlFor="name" className="log-reg-label">
                        Description
                    </label>
                    <textarea
                        rows="4"
                        cols="50"
                        name="name"
                        className="textarea-input"
                        onChange={(e) => setNewDescription(e.target.value)}
                    ></textarea>
                    <div>
                        <button onClick={hideEditDescription} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={editDescription} className="save-btn">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const detailsPopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Edit details</h1>
                    <label htmlFor="name" className="log-reg-label">
                        Details
                    </label>
                    <textarea
                        rows="4"
                        cols="50"
                        name="name"
                        className="textarea-input"
                        onChange={(e) => setNewDetails(e.target.value)}
                    ></textarea>
                    <div>
                        <button onClick={hideEditDetails} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={editDetails} className="save-btn">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleFileChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    const handleUploadProfilePicture = () => {
        if (profilePicture) {
            uploadProfilePicture(profilePicture);
        }
    };

    const picturePopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h2>Upload Picture</h2>
                    <input type="file" onChange={handleFileChange} />
                    <div className="popup-buttons">
                        <button onClick={hideEditProfilePicture} className="go-back-btn">
                            Cancel
                        </button>
                        <button onClick={handleUploadProfilePicture} className="save-btn">
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const removeTherapistPopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Remove your current therapist?</h1>
                    <div>
                        <button onClick={hideRemoveTherapist} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={removeTherapist} className="save-btn">
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const deleteAccountPopup = () => {
        return (
            <div className="popup-container">
                <div className="popup-inner-container">
                    <h1 className="edit-title">Delete your account?</h1>
                    <div>
                        <button onClick={hideDeleteAccount} className="go-back-btn">
                            Go back
                        </button>
                        <button onClick={deleteAccount} className="save-btn">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    Your <span className="orange-accent">Profile</span>
                </h1>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <div className="profile">
                    {showPasswordPopup && passwordPopup()}
                    {showNamePopup && namePopup()}
                    {showRemoveTherapistPopup && removeTherapistPopup()}
                    {showDeleteAccountPopup && deleteAccountPopup()}
                    {showDescriptionPopup && descriptionPopup()}
                    {showDetailsPopup && detailsPopup()}
                    {showPicturePopup && picturePopup()}
                    <div className="my-profile-container">
                        {user.Role.Name === "ROLE_THERAPIST"
                            ? loadTherapistView(user)
                            : loadPatientView(user)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyProfile;
