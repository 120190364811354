import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import Spinner from "../components/Spinner";
import ChatMessage from "../components/ChatMessage";
import GoBack from "../components/GoBack";

const ChatConversation = () => {
    const { auth } = useAuth();
    const { userID } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [conversation, setConversation] = useState([]);
    const [conversationID, setConversationID] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);
    const chatContainerRef = useRef(null);
    const ws = useRef(null);

    useEffect(() => {
        fetchMessages();
    }, []);

    useEffect(() => {
        if (conversationID) {
            const websocketUrl = "wss://server-side-mental.fly.dev";
            const wsInstance = new WebSocket(
                `${websocketUrl}/chat/conversation/${conversationID}?token=${auth.accessToken}`
            );
            ws.current = wsInstance;

            wsInstance.onmessage = handleMessage;
            wsInstance.onerror = () => setError("WebSocket connection error.");
            wsInstance.onclose = () => setError("WebSocket connection closed.");

            return () => {
                if (wsInstance.readyState === WebSocket.OPEN) {
                    wsInstance.close();
                }
            };
        }
    }, [auth.accessToken, conversationID]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [conversation]);

    const fetchMessages = async () => {
        try {
            setLoading(true);
            const response = await axiosPrivate.get(`/chat/user/${userID}/messages`);
            setConversation(response.data.data.messages);
            setParticipants(
                response.data.data.participants.filter(
                    (participant) => participant.userID !== auth.userID
                )
            );
            setConversationID(response.data.data.conversationID);
            setLoading(false);
        } catch (error) {
            setError("Failed to fetch messages: " + error.message);
            setLoading(false);
        }
    };

    const handleMessage = (event) => {
        try {
            const data = JSON.parse(atob(JSON.parse(event.data)));
            setConversation((prevConversation) => [...prevConversation, data]);
        } catch (error) {
            console.error("Error parsing received data:", error);
        }
    };

    const sendMessage = () => {
        try {
            if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
                throw new Error("WebSocket connection is not open");
            }
    
            if (!message.trim()) {
                return; // Don't send empty message
            }
    
            const messageObject = {
                type: "new_message",
                conversationID: conversationID,
                userID: auth.userID,
                content: message,
            };
    
            ws.current.send(JSON.stringify(messageObject));
            setMessage("");
        } catch (error) {
            setError("Failed to send message: " + error.message);
        } finally {
            setSending(false);
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : error ? (
                <div>Error: {error}</div>
            ) : (
                <>
                    <div className="top-controls">
                        <GoBack />
                        <h1 className="main-title">
                            <span className="orange-accent">Chat </span>
                            <span>with </span>
                            {participants.map((participant, index) => (
                                <span key={index}>{participant.name} </span>
                            ))}
                        </h1>
                    </div>
                    <div className="chat-container-wrapper" ref={chatContainerRef}>
                        <div className="chat-container">
                            {conversation.map((msg, index) => (
                                <ChatMessage
                                    key={index}
                                    message={msg}
                                    currentUserID={auth.userID}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="chat-input-container">
                        <textarea
                            className="chat-textarea"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    sendMessage();
                                }
                            }}
                            placeholder="Type your message here..."
                        />
                        <button className="chat-button" onClick={sendMessage} disabled={sending}>
                            {sending ? "Sending..." : "Send"}
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default ChatConversation;
