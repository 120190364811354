import React, { useEffect, useState } from "react";
import ChatCard from "../components/ChatCard";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import GoBack from "../components/GoBack";
import NoResults from "../components/NoResults";

const ChatHome = () => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadChat = async () => {
            try {
                const response = await axiosPrivate.get("/chat");
                const data = response.data.data;
                if (data && data.users) {
                    setUsers(data.users);
                } else {
                    setError("No users found");
                }
            } catch (err) {
                setError("Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };

        loadChat();
    }, [axiosPrivate]);

    return (
        <div>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    <span className="orange-accent">Chat</span> is here
                </h1>
            </div>
            {loading ? (
                <Spinner />
            ) : error ? (
                <div>{error}</div>
            ) : users.length === 0 ? (
                <NoResults message="Choose a therapist first"/>
            ) : (
                <div className="chat-list-container">
                    {users.map((user) => (
                        <ChatCard key={user.ID} user={user} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChatHome;
