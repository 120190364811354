import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import plus from "../icons/plus.svg";
import NoResults from "../components/NoResults";
import GoBack from "../components/GoBack";
import MonthlyChart from "../components/MonthlyChart";
import MoodCountChart from "../components/MoodCountChart";
import VersusChart from   "../components/VersusChart"

const MyJournal = () => {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // New state for error message
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosPrivate.post("/journal/home");
                setData(response.data.data);
            } catch (err) {
                console.error("Error fetching journal data:", err);
                setError("Error fetching journal data. Please try again."); // Set error message
                navigate("/login", { state: { from: location }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [axiosPrivate, navigate, location]); // Add dependencies here

    const addEntry = (e) => {
        e.preventDefault();
        navigate("/journal/entry/add");
    };

    if (loading) {
        return <Spinner />;
    }

    // If there's an error, display error message
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="journal-title-container">
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                Your <span className="orange-accent">Journal</span>
                </h1>
            </div>
                <div className="journal-controls-container">
                    <Link className="entries-link" to="/journal/entries">
                        View entries
                    </Link>
                    <button className="plus-btn" onClick={addEntry}>
                        <img className="menu-icon navbtn" src={plus} alt="logo" />
                    </button>
                </div>
            </div>
            {data.journalEntries === null ? (
                <NoResults />
            ) : (
                <div className="dashboard-container">
                    <MonthlyChart records={data.journalEntries} />
                    <MoodCountChart counts={data.moodCounts}/>
                    <VersusChart label1="At home" label2="Outside" count1={data.homeCounts.home} count2={data.homeCounts.outside} title={"Days spent"}/>
                    <VersusChart label1="Alcohol" label2="No alcohol" count1={data.alcoholCounts.alcohol} count2={data.alcoholCounts.noAlcohol} title={"Days with"}/>
                </div>
            )}
        </>
    );
};

export default MyJournal;
