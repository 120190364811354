import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home-container" role="main">
      {/* Hidden skip links */}
      <div className="skip-links">
        <a href="#main-content" className="skip-link">Skip to main content</a>
        <a href="#menu" className="skip-link">Skip to menu</a>
        <a href="#footer" className="skip-link">Skip to footer</a>
      </div>

      <div className="home-div home-left">
        <div className="logo-container">
          <h1 className="logo-text">mental</h1>
          <img className="logo" src={logo} alt="logo"/>
        </div>
        <p className="home-lead home-p">
          Help when <span className="home-accent">YOU</span> need it
        </p>
        <p className="home-subtext home-p">Help when you need it</p>
      </div>
      <div className="home-div home-right">
        <div className="home-margin">
          <Link to="/login" className="home-link">
            <div className="home-link-div">
              <div className="home-opacity">Login</div>
            </div>
          </Link>
          <Link to="/register" className="home-link">
            <div className="home-link-div">
              <div className="home-opacity">Sign Up</div>
            </div>
          </Link>
          <Link to="/login" className="home-link">
            <div className="home-link-div">
              <div className="home-opacity">Book appointment</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
