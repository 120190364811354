import React, { useState, useEffect } from "react";
import ArrowLeft from "../icons/arrow-left-circle.svg";
import ArrowRight from "../icons/arrow-right-circle.svg";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import GoBack from "../components/GoBack";
import { useNavigate } from "react-router-dom";

const Appointments = () => {
    const names = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [calendar, setCalendar] = useState([]);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [therapistName, setTherapistName] = useState(null);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectedDayId, setSelectedDayId] = useState(null);
    const [selectedTimeId, setSelectedTimeId] = useState(null);
    const [isCurrentMonth, setIsCurrentMonth] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const getDate = () => {
            if (!selectedMonth) {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const year = currentDate.getFullYear();
                setSelectedMonth(currentMonth.toString());
                setSelectedYear(year.toString());
            }
        };

        getDate();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchCalendarData = async () => {
            setSelectedDayId(null);
            setSelectedTimeId(null);
            try {
                const response = await axiosPrivate.post("/appointment/calendar", {
                    selectedMonth,
                    selectedYear,
                });
                const { month, year, appointments, therapistName } = response.data.data;
                setMonth(month);
                setYear(year);
                setCalendar(appointments);
                setTherapistName(therapistName);
                setErrorMessage(""); // Reset error message on successful response
            } catch (err) {
                if (err.response.data.error === "User does not have a therapist") {
                    navigate("/search/therapists");
                }
                if (err.response && err.response.data && err.response.data.error) {
                    setErrorMessage(err.response.data.error);
                } else {
                    setErrorMessage("An error occurred while fetching calendar data.");
                }
            } finally {
                setLoading(false);
            }
        };

        if (selectedMonth) {
            fetchCalendarData();
        }
    }, [selectedMonth, selectedYear, axiosPrivate, navigate]);

    useEffect(() => {
        reloadSelectedTimes();
        // eslint-disable-next-line
    }, [selectedTimes]);

    useEffect(() => {
        reloadSelectedAppointment();
        // eslint-disable-next-line
    }, [selectedTimes, selectedTimeId]);

    useEffect(() => {
        isCurrentMonthSelected();
        // eslint-disable-next-line
    }, [selectedMonth]);

    const removeDaySelected = () => {
        const prevSelectedButton = document.querySelector(".day-slot.day-selected");
        if (prevSelectedButton) {
            prevSelectedButton.classList.remove("day-selected");
        }
    };

    const removeTimeSelected = () => {
        const prevSelectedButton = document.querySelector(".time-slot.time-selected");
        if (prevSelectedButton) {
            prevSelectedButton.classList.remove("time-selected");
        }
    };

    const selectTime = (e) => {
        e.preventDefault();
        const newSelectedTimeId = e.target.id;
        removeTimeSelected();
        e.target.classList.add("time-selected");
        setSelectedTimeId(newSelectedTimeId);
    };

    const isCurrentMonthSelected = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const selectedMonthInt = parseInt(selectedMonth);
        setIsCurrentMonth(currentMonth === selectedMonthInt);
    };

    const reloadSelectedTimes = () => {
        return selectedTimes.length ? (
            selectedTimes.map((time, i) => (
                <button
                    disabled={!time.Available}
                    onClick={selectTime}
                    key={i}
                    id={i}
                    className={`time-slot ${time.Available ? "" : "slot-booked"}`}
                >
                    {time.Hour}:00
                </button>
            ))
        ) : (
            <div className="time-slot">Select Day</div>
        );
    };

    const bookAppointment = async (e) => {
        e.preventDefault();
        const selectedDayBook = calendar[selectedDayId];
        const selectedTime = selectedDayBook?.Times[selectedTimeId];
        try {
            const response = await axiosPrivate.post("/appointment/book", {
                selectedDay: selectedDayBook.Day.toString(),
                selectedMonth: selectedDayBook.Month.toString(),
                selectedYear: selectedDayBook.Year.toString(),
                selectedTime: selectedTime.Hour.toString(),
            });

            if (response.status === 200) {
                setShowPopup(true);
            }
        } catch (err) {}
    };

    const prevMonth = (e) => {
        e.preventDefault();
        setSelectedDayId(null);
        setSelectedTimeId(null);
        let selectedMonthInt = parseInt(selectedMonth);
        let newSelectedMonth = selectedMonthInt - 1;
        let newSelectedYear = parseInt(selectedYear);
        if (newSelectedMonth < 1) {
            newSelectedMonth = 12;
            newSelectedYear -= 1;
        }
        setSelectedMonth(newSelectedMonth.toString());
        setSelectedYear(newSelectedYear.toString());
    };

    const nextMonth = (e) => {
        e.preventDefault();
        setSelectedDayId(null);
        setSelectedTimeId(null);
        let selectedMonthInt = parseInt(selectedMonth);
        let newSelectedMonth = selectedMonthInt + 1;
        let newSelectedYear = parseInt(selectedYear);
        if (newSelectedMonth > 12) {
            newSelectedMonth = 1;
            newSelectedYear += 1;
        }
        setSelectedMonth(newSelectedMonth.toString());
        setSelectedYear(newSelectedYear.toString());
    };

    const reloadSelectedAppointment = () => {
        const selectedDay = calendar[selectedDayId];
        const selectedTime = selectedDay?.Times[selectedTimeId];
        return (
            <>
                <div className="detail-container">
                    <span className="detail-head">Day</span>
                    <span className="detail-info">
                        {selectedDay?.Weekday !== undefined ? names[selectedDay.Weekday - 1] : " "}{" "}
                        {selectedDay?.Day ? selectedDay.Day : " "}{" "}
                        {selectedDay?.Month ? selectedDay.Month : " "}{" "}
                        {selectedDay?.Year ? selectedDay.Year : " "}
                    </span>
                </div>
                <div className="detail-container">
                    <span className="detail-head">Time</span>
                    <span className="detail-info">
                        {selectedTime?.Hour ? `${selectedTime.Hour}:00` : " "}
                    </span>
                </div>
                <div className="detail-container">
                    <span className="detail-head">With</span>
                    <span className="detail-info">{therapistName}</span>
                </div>
                <button
                    onClick={bookAppointment}
                    className={`appointment-button ${
                        selectedDay && selectedTime ? "" : "btn-disabled"
                    }`}
                    disabled={!selectedDay && !selectedTime}
                >
                    Book
                </button>
            </>
        );
    };

    const selectDay = (e) => {
        e.preventDefault();
        const newSelectedDayId = e.target.id;
        removeDaySelected();
        e.target.classList.add("day-selected");
        const selectedDay = calendar[newSelectedDayId];
        setSelectedTimes(selectedDay.Times);
        setSelectedDayId(newSelectedDayId);
        setSelectedTimeId(null);
        removeTimeSelected();
    };

    const listNames = names.map((name) => <span key={name}>{name}</span>);

    const listDays = calendar.map((day, i) =>
        i === 0 ? (
            <button
                disabled={!day.Available}
                onClick={selectDay}
                className={`day-slot ${"day-slot-offset-" + day.Weekday} ${
                    day.Available ? "" : "day-btn-disabled"
                }`}
                key={i}
                id={i}
            >
                {day.Day}
            </button>
        ) : (
            <button
                disabled={!day.Available}
                onClick={selectDay}
                className={`day-slot ${day.Available ? "" : "day-btn-disabled"}`}
                key={i}
                id={i}
            >
                {day.Day}
            </button>
        )
    );

    return (
        <>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    Book your <span className="orange-accent">Appointment</span>
                </h1>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {showPopup && (
                        <div className="popup-container">
                            <div className="popup-inner-container">
                                <p className="popup-message">You are all booked!</p>
                                <p className="popup-message-small">
                                    Your appointment was booked successfully!
                                </p>
                                <Link to="/user/home" className="back-to-user-home-btn">
                                    Home
                                </Link>
                            </div>
                        </div>
                    )}
                    <div className="appointment-container">
                        <div className="calendar-container">
                            <div className="calendar-controls">
                                <button
                                    disabled={isCurrentMonth}
                                    onClick={prevMonth}
                                    className={`clear-button `}
                                >
                                    <img
                                        src={ArrowLeft}
                                        alt="back arrow"
                                        className={`calendar-arrow ${
                                            isCurrentMonth ? "hidden" : ""
                                        }`}
                                    />
                                </button>
                                <span className="calendar-month">
                                    {month} {year}
                                </span>
                                <button onClick={nextMonth} className="clear-button">
                                    <img
                                        src={ArrowRight}
                                        alt="next arrow"
                                        className="calendar-arrow"
                                    />
                                </button>
                            </div>
                            <div className="calendar-week-container">
                                <div className="calendar-days-container names-container">
                                    {listNames}
                                </div>
                                <div className="calendar-days-container days-container">
                                    {listDays}
                                </div>
                            </div>
                        </div>
                        <div className="hours-container">{reloadSelectedTimes()}</div>
                        <div className="details-container">
                            <h1 className="detail-title">Appointment</h1>
                            {reloadSelectedAppointment()}
                        </div>
                    </div>
                </>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </>
    );
};

export default Appointments;
