import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";
import Spinner from "./Spinner";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth } = useAuth();
  const [persist] = useLocalStorage("persist", true);
  const refresh = useRefreshToken(); // Move the hook here

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh(); // Use refresh directly here
      } catch (err) {
        // Handle error if needed
      } finally {
        setIsLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    if (!auth?.accessToken && persist) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.accessToken, persist]);

  return !persist ? <Outlet /> : isLoading ? <Spinner /> : <Outlet />;
};

export default PersistLogin;