import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import logo from "../images/logo.png";
import arrow from "../icons/left-arrow.svg";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/user/register";

const Register = () => {
    const usernameRef = useRef(null);
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        matchPassword: "",
        firstName: "",
        role: "ROLE_PATIENT"
    });
    const [validInput, setValidInput] = useState({
        username: false,
        password: false,
        matchPassword: false,
        firstName: false
    });
    const [errMsg, setErrMsg] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (usernameRef.current) {
            usernameRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setValidInput(prevState => ({
            ...prevState,
            username: USER_REGEX.test(formData.username)
        }));
    }, [formData.username]);

    useEffect(() => {
        setValidInput(prevState => ({
            ...prevState,
            password: PWD_REGEX.test(formData.password),
            matchPassword: formData.password === formData.matchPassword
        }));
    }, [formData.password, formData.matchPassword]);

    useEffect(() => {
        setValidInput(prevState => ({
            ...prevState,
            firstName: USER_REGEX.test(formData.firstName)
        }));
    }, [formData.firstName]);

    useEffect(() => {
        setErrMsg("");
    }, [formData.username, formData.password, formData.matchPassword]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { username, password, firstName } = formData;

        if (!validInput.username || !validInput.password || !validInput.matchPassword || !validInput.firstName) {
            setErrMsg("Please check your inputs and try again.");
            return;
        }

        try {
            const response = await axios.post(
                REGISTER_URL,
                JSON.stringify({
                    username,
                    firstName,
                    password,
                    confirm_password: formData.matchPassword,
                    role: formData.role,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                setShowPopup(true);
            }

            setFormData({
                username: "",
                password: "",
                matchPassword: "",
                firstName: "",
                role: "ROLE_PATIENT"
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setErrMsg(err.response.data.error);
            } else {
                setErrMsg("Something went wrong. Please try again later.");
            }
        }
    };

    return (
        <>
            {showPopup && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">You have successfully Signed Up!</p>
                        <p className="popup-message-small">You can now Login</p>
                        <Link to="/login" className="back-to-user-home-btn">
                            Login
                        </Link>
                    </div>
                </div>
            )}
            <div className="log-reg-container">
                <div className="reg-top">
                    <img className="log-reg-logo" src={logo} alt="logo" />
                    <div className="log-reg-div">
                        <form className="log-reg-form" onSubmit={handleSubmit}>
                            <Link to="/" className="reg-login-link">
                                <div className="d-flex">
                                    <img src={arrow} alt="back arrow" />
                                    <span>Home</span>
                                </div>
                            </Link>
                            <h1 className="reg-lead">Register</h1>
                            {["username", "password", "matchPassword", "firstName"].map(field => (
                                <React.Fragment key={field}>
                                    <label htmlFor={field} className="log-reg-label">
                                        {field === "matchPassword" ? "Confirm Password" : field === "firstName" ? "First Name" : field.charAt(0).toUpperCase() + field.slice(1)}
                                    </label>
                                    <input
                                        type={field === "password" || field === "matchPassword" ? "password" : "text"}
                                        name={field}
                                        className="reg-input"
                                        value={formData[field]}
                                        onChange={handleChange}
                                        ref={field === "username" ? usernameRef : null}
                                    />
                                    {field === "username" && (
                                        <p className={`note ${formData.username && !validInput.username ? "active" : "hidden"}`}>
                                            4 to 24 characters. Must begin with a letter. Letters, numbers, underscores, hyphens allowed.
                                        </p>
                                    )}
                                    {field === "password" && (
                                        <p className={`note ${formData.password && !validInput.password ? "active" : "hidden"}`}>
                                            8 to 24 characters. Must include uppercase and lowercase letters, a number, and a special character. Allowed special characters: ! @ # $ %
                                        </p>
                                    )}
                                    {field === "matchPassword" && (
                                        <p className={`note ${formData.matchPassword && !validInput.matchPassword ? "active" : "hidden"}`}>
                                            Must match the first password input field.
                                        </p>
                                    )}
                                    {field === "firstName" && (
                                        <p className={`note ${formData.firstName && !validInput.firstName ? "active" : "hidden"}`}>
                                            4 to 24 characters. Must begin with a letter. Letters, numbers, underscores, hyphens allowed.
                                        </p>
                                    )}
                                </React.Fragment>
                            ))}
                            <div className="log-reg-radio-container">
                                {["ROLE_PATIENT", "ROLE_THERAPIST"].map(roleValue => (
                                    <React.Fragment key={roleValue}>
                                        <input
                                            type="radio"
                                            id={roleValue.toLowerCase()}
                                            name="role"
                                            value={roleValue}
                                            checked={formData.role === roleValue}
                                            onChange={handleChange}
                                            className="hidden"
                                        />
                                        <label htmlFor={roleValue.toLowerCase()} className="log-reg-button-label">
                                            {roleValue === "ROLE_PATIENT" ? "Patient" : "Therapist"}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                            <button className="reg-button">Sign Up</button>
                            <p className={`errorMsg ${errMsg ? "active" : "hidden"}`} aria-live="assertive">{errMsg}</p>
                        </form>
                        <div className="reg-login">
                            <Link to="/login" className="reg-login-link">Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
