import React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState } from "react";

const AppointmentCard = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [showPopupAsk, setShowPopupAsk] = useState(false);
    const [showPopupConfirm, setShowPopupConfirm] = useState(false);

    const cancelAppointment = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.delete("/appointment/cancel/" + props.appointment.ID.toString())

            if (response.status === 200) {
                setShowPopupAsk(false);
                setShowPopupConfirm(true);
            }
        } catch (err) {

        }
    };

    const cancelAppointmentAsk = (e) => {
        e.preventDefault();
        setShowPopupAsk(true);
    };

    const goBack = (e) => {
        e.preventDefault();
        setShowPopupAsk(false);
    };

    const refresh = (e) => {
        e.preventDefault();
        window.location.reload();
    };

    const formatSlotDate = (slot) => {
        const date = new Date(slot);
        const options = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        const formattedDate = date.toLocaleDateString('en-GB', options);
        const time = new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(date);
        return { formattedDate, time };
    };

    return (
        <>
            {showPopupAsk && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">Are you sure?</p>
                        <p className="popup-message-small">Do you want to cancel your appointment?</p>
                        <button onClick={cancelAppointment} className="cancel-appointment-btn">
                            Cancel Appointment
                        </button>
                        <button onClick={goBack} className="go-back-appointment-btn">
                            Go Back
                        </button>
                    </div>
                </div>
            )}
            {showPopupConfirm && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">Your appointment has been cancelled!</p>
                        <p className="popup-message-small">You can go back now.</p>
                        <button onClick={refresh} className="go-back-appointment-btn">
                            Go Back
                        </button>
                    </div>
                </div>
            )}
            <div className="appointment-slot">
                <div className="appointment-section">
                    <h1 className="appointment-title">Therapist</h1>
                    <p className="appointment-paragraph">{props.appointment.Therapist.Name}</p>
                </div>
                <div className="appointment-section">
                    <h1 className="appointment-title">Date</h1>
                    <p className="appointment-paragraph">{formatSlotDate(props.appointment.Slot).formattedDate}</p>
                </div>
                <div className="appointment-section">
                    <h1 className="appointment-title">Time</h1>
                    <p className="appointment-paragraph">{formatSlotDate(props.appointment.Slot).time}</p>
                </div>
                {props.allowCancel && (
                    <button
                        id={props.appointment.ID}
                        onClick={cancelAppointmentAsk}
                        className={`appointment-cancel-btn ${props.appointment.Canceled ? "appointment-cancelled" : ""}`}
                        disabled={props.appointment.Canceled}
                    >
                        Cancel
                    </button>
                )}
            </div>
        </>
    );
};

export default AppointmentCard;
