import React from "react";

const ChatMessage = ({ message, currentUserID }) => {
    // Determine if the message is sent by the current user or the therapist
    const isCurrentUserMessage = message.userID === currentUserID;

    // Define the class name based on the sender
    const className = isCurrentUserMessage
        ? "chat-message-container chat-message-sender"
        : "chat-message-container chat-message-receiver";

    return (
        <div className={className}>
            <p className="chat-message-content">
                {message.content}
            </p>
        </div>
    );
};

export default ChatMessage;
