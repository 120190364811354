import React from "react";
import veryHappy from "../icons/emotions/veryHappy.svg";
import happy from "../icons/emotions/happy.svg";
import neutral from "../icons/emotions/neutral.svg";
import angry from "../icons/emotions/angry.svg";
import sad from "../icons/emotions/sad.svg";
import depressed from "../icons/emotions/depressed.svg";

const MoodCard = (props) => {
    const calcMood = (mood) => {
        let moodImage, moodText;

        switch (mood) {
            case 1:
                moodImage = depressed;
                moodText = "Depressed";
                break;
            case 2:
                moodImage = sad;
                moodText = "Sad";
                break;
            case 3:
                moodImage = angry;
                moodText = "Angry";
                break;
            case 4:
                moodImage = neutral;
                moodText = "Neutral";
                break;
            case 5:
                moodImage = happy;
                moodText = "Happy";
                break;
            case 6:
                moodImage = veryHappy;
                moodText = "Very Happy";
                break;

            default:
                break;
        }

        return (
            <div className="mood-container">
                <p className="mood-desc">{moodText}</p>
                <img className="mood-icon" src={moodImage} alt="Mood" />
            </div>
        );
    };

    return <div className="mood-card">{calcMood(props.mood)}</div>;
};

export default MoodCard;
