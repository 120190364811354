import React from "react";
import { Routes, Route } from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Therapists from "./pages/Therapists";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserHome from "./pages/UserHome";
import User from "./pages/Therapist";
import Calendar from "./pages/Calendar";
import MyAppointments from "./pages/MyAppointments";
import MyProfile from "./pages/MyProfile";
import MyJournalEntries from "./pages/MyJournalEntries";
import MyJournal from "./pages/MyJournal";
import AddJournalEntry from "./pages/AddJournalEntry";
import ChatHome from "./pages/ChatHome";
import ChatConversation from "./pages/ChatConversation";

function App() {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                    <Route path="/" element={<Layout />}>
                        <Route path="user/home" element={<UserHome />} />
                        <Route path="user/profile" element={<MyProfile />} />
                        <Route path="user/appointments" element={<MyAppointments />} />
                        <Route path="search/therapists" element={<Therapists />} />
                        <Route path="search/therapist/:therapistUsername" element={<User />} />
                        <Route path="appointment/calendar" element={<Calendar />} />
                        <Route path="journal/home" element={<MyJournal />} />
                        <Route path="journal/entries" element={<MyJournalEntries />} />
                        <Route path="journal/entry/add" element={<AddJournalEntry />} />
                        <Route path="chat" element={<ChatHome />} />
                        <Route path="chat/user/:userID" element={<ChatConversation />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
