import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import AppointmentCard from "../components/AppointmentCard";
import JournalEntryCard from "../components/JournalEntryCard";
import ChatCard from "../components/ChatCard";
import NoResults from "../components/NoResults";

const UserHome = () => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [lastJournalEntry, setLastJournalEntry] = useState("");
    const [therapist, setTherapist] = useState("");

    useEffect(() => {
        const fetchUserHomeScreen = async () => {
            try {
                const response = await axiosPrivate.get("/user/home");
                const { appointments, lastJournalEntry, therapist } = response.data.data;
                setAppointments(appointments);
                setLastJournalEntry(lastJournalEntry);
                setTherapist(therapist);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch data. Please try again later.");
                setLoading(false);
            }
        };

        fetchUserHomeScreen();
    }, [axiosPrivate]);

    return (
        <div className="user-home-container">
            {loading ? (
                <Spinner />
            ) : error ? (
                <div>Error: {error}</div>
            ) : (
                <>
                    <div className="user-home-title-container">
                        <h1 className="user-home-title">
                            Breath <span className="orange-accent">in</span>...
                        </h1>
                        <h1 className="user-home-title align-right">
                            Breath <span className="orange-accent">out</span>...
                        </h1>
                    </div>
                    <div className="user-home-div">
                        <h2 className="home-h2">Your upcoming appointments</h2>
                        {appointments.length > 0 ? (
                            <>
                                {appointments.map((appointment, index) => (
                                    <AppointmentCard
                                        key={index}
                                        appointment={appointment}
                                        allowCancel={false}
                                    />
                                ))}
                                <Link to="/user/appointments" className="user-home-link">
                                    <p className="user-home-p">View your appointments</p>
                                </Link>
                            </>
                        ) : (
                            <NoResults message="No upcoming appointments." />
                        )}
                    </div>
                    <div className="user-home-div">
                        <h2 className="home-h2">Your last journal entry</h2>
                        {lastJournalEntry ? (
                            <JournalEntryCard entry={lastJournalEntry} allowDelete={false} />
                        ) : (
                            <NoResults message="No journal entries found." />
                        )}
                        <Link to="/journal/home" className="user-home-link">
                            <p className="user-home-p">View your journal</p>
                        </Link>
                    </div>

                    <div className="user-home-div">
                        <h2 className="home-h2">Chat to your therapist</h2>
                        {therapist ? (
                            <ChatCard user={therapist} />
                        ) : (
                            <NoResults message="No therapist available for chat." />
                        )}
                        <Link to="/chat" className="user-home-link">
                            <p className="user-home-p">Chat</p>
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserHome;
