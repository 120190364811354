import { axiosPrivate } from "../api/axios";
import { useEffect, useRef } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth, setAuth } = useAuth();
  const isMounted = useRef(true);
  const isRefreshing = useRef(false);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 403 && !prevRequest?.sent) {
          if (!isRefreshing.current) {
            isRefreshing.current = true;

            try {
              const newAccessToken = await refresh();
              setAuth((prev) => ({
                ...prev,
                accessToken: newAccessToken,
              }));

              // Retry the original request with the new token
              prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              prevRequest.sent = true;
              return axiosPrivate(prevRequest);
            } catch (refreshError) {
              // Handle refresh error (e.g., redirect to login)
              console.error('Token refresh error:', refreshError);
              return Promise.reject(error);
            } finally {
              isRefreshing.current = false;
            }
          }
        }

        return Promise.reject(error);
      }
    );

    // Cleanup function
    return () => {
      if (isMounted.current) {
        isMounted.current = false;
        axiosPrivate.interceptors.request.eject(requestIntercept);
        axiosPrivate.interceptors.response.eject(responseIntercept);
      }
    };
  }, [auth, refresh, setAuth]);

  return axiosPrivate;
};

export default useAxiosPrivate;
