import React, { useState } from "react";
import MoodCard from "./MoodCard";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const JournalEntryCard = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [deleteEntryAskPopup, setDeleteEntryAskPopup] = useState(false);
    const [showPopupConfirm, setShowPopupConfirm] = useState(false);

    const calcDate = (date) => {
        const newDate = date.substring(0, 10);
        return newDate;
    };

    const deleteEntry = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.delete(
                "/journal/entry/delete/" + props.entry.ID.toString()
            );
            if (response.status === 200) {
                setDeleteEntryAskPopup(false);
                setShowPopupConfirm(true);
            }
        } catch (err) {}
    };

    const deleteEntryAsk = (e) => {
        e.preventDefault();
        setDeleteEntryAskPopup(true);
    };

    const goBack = (e) => {
        e.preventDefault();
        setDeleteEntryAskPopup(false);
    };

    const refresh = (e) => {
        e.preventDefault();
        window.location.reload();
    };

    return (
        <>
            {deleteEntryAskPopup && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">Are you sure?</p>
                        <p className="popup-message-small">
                            Do you want to delete the entry?
                        </p>
                        <button onClick={deleteEntry} className="delete-entry-btn">
                            Delete
                        </button>
                        <button onClick={goBack} className="go-back-entry-btn">
                            Go Back
                        </button>
                    </div>
                </div>
            )}
            {showPopupConfirm && (
                <div className="popup-container">
                    <div className="popup-inner-container">
                        <p className="popup-message">Your entry has been deleted!</p>
                        <p className="popup-message-small">You can go back now.</p>
                        <button onClick={refresh} className="go-back-appointment-btn">
                            Go Back
                        </button>
                    </div>
                </div>
            )}
            <div className="entry-container">
                <div className="entry-date-container">
                    <h1 className="entry-date">{calcDate(props.entry.Date)}</h1>
                    <MoodCard mood={props.entry.Mood} />
                </div>
                <h2 className="entry-h2">Your day</h2>
                <p className="entry-text">{props.entry.Text}</p>
                {props.allowDelete && (
                    <button
                        id={props.entry.ID}
                        className="entry-cancel-btn"
                        onClick={deleteEntryAsk}
                    >
                        Delete
                    </button>
                )}
            </div>
        </>
    );
};

export default JournalEntryCard;
