import React, { useState, useEffect } from "react";
import UserCard from "../components/UserCard";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../components/Spinner";
import NoResults from "../components/NoResults";
import GoBack from "../components/GoBack";

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosPrivate.get(`/search?page=${currentPage}`);
                setUsers(response.data.data);
                setTotalPages(response.data.meta.totalPages); // Update totalPages from meta
            } catch (err) {
                navigate("/login", { state: { from: location }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [axiosPrivate, currentPage, navigate, location]);

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const noResults = users.length === 0;

    return (
        <div>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    The right <span className="orange-accent">fit</span> is here
                </h1>
            </div>

            {loading ? (
                <Spinner />
            ) : noResults ? (
                <NoResults />
            ) : (
                <div>
                    <div className="cards-container">
                        {users.map((user, index) => (
                            <UserCard key={index} user={user} />
                        ))}
                    </div>
                    <div className="pagination">
                        <button className="pagination-bttn" onClick={handlePrevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                        <button className="pagination-bttn" onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsersList;
