import React from "react";

import veryHappy from "../icons/emotions/veryHappy.svg";
import happy from "../icons/emotions/happy.svg";
import neutral from "../icons/emotions/neutral.svg";
import angry from "../icons/emotions/angry.svg";
import sad from "../icons/emotions/sad.svg";
import depressed from "../icons/emotions/depressed.svg";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const MoodCountChart = (props) => {
    const chartData = {
        labels: ["Angry", "Depressed", "Happy", "Neutral", "Sad", "Very Happy"],
        datasets: [
            {
                data: Object.values(props.counts),
                backgroundColor: [
                    "#F4442E", // Angry
                    "#58355E", // Depressed
                    "#5DA271", // Happy
                    "#FFFFFF", // Neutral
                    "#2D7DD2", // Sad
                    "#efff40", // Very Happy
                ],
                borderWidth: 0, // Set to 0 to remove the border
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="mood-count-chart">
            <h2 className="chart-title">Mood counts</h2>
            <div className="mood-count-chart-container">
                <Doughnut
                    className="mood-count-chart"
                    data={chartData}
                    options={chartOptions}
                    aria-label="Mood Count Chart"
                />
            </div>
            <div className="mood-count-moods-container">
                {Object.keys(props.counts).map((mood, index) => (
                    <div key={index} className="mood-with-badge position-relative">
                        <img
                            className="mood-icon-with-badge"
                            src={moodImages[mood]}
                            alt={`${mood} Emoji`}
                        />
                        <span className="position-absolute badge-position translate-middle badge rounded-pill">
                            {props.counts[mood]}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Map mood names to their respective images
const moodImages = {
    veryHappy,
    happy,
    neutral,
    angry,
    sad,
    depressed,
};

export default MoodCountChart;
