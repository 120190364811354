import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import logo from "../images/logo.png";

const Image = ({ fileName }) => {
    const axiosPrivate = useAxiosPrivate();
    const [imageData, setImageData] = useState("");

    useEffect(() => {
        const fetchImage = async () => {
            // Check if fileName is empty
            if (!fileName) {
                // Set default image data (logo) if fileName is empty
                setImageData(logo);
                return;
            }
    
            try {
                const response = await axiosPrivate.get(`user/profile-picture/${fileName}`, {
                    responseType: 'blob' // Set responseType to blob to receive image data
                });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageData(reader.result); // Set image data as source for img tag
                };
                reader.readAsDataURL(response.data); // Read image data as base64 string
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };
    
        fetchImage();
    }, [axiosPrivate, fileName]);

    return (
        // Conditional rendering based on whether imageData is empty or not
        <img className="profile-picture" src={imageData || logo} alt="Profile" />
    );
};

export default Image;
