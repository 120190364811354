import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "../components/Spinner";
import Image from "../components/Image";
import GoBack from "../components/GoBack";

const Therapist = () => {
    const { therapistUsername } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axiosPrivate.get("/search/" + therapistUsername);
                setUser(response.data.data);
            } catch (err) {
                navigate("/login", { state: { from: location }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, [axiosPrivate, therapistUsername, navigate, location]);

    const chooseTherapist = async (e) => {
        e.preventDefault();

        try {
            await axiosPrivate.put("/user/choose", {
                therapistUsername: therapistUsername,
            });

            setShowPopup(true);
        } catch (err) {
            navigate("/login", { state: { from: location }, replace: true });
        }
    };

    return (
        <div>
            <div className="top-controls">
                <GoBack />
                <h1 className="main-title">
                    The right <span className="orange-accent">fit</span> is here
                </h1>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    {showPopup && (
                        <div className="popup-container">
                            <div className="popup-inner-container">
                                <p className="popup-message">You have chosen!</p>
                                <p className="popup-message-small">
                                    You can now book your appointment!
                                </p>
                                <Link to="/appointment/calendar" className="back-to-user-home-btn">
                                    Book
                                </Link>
                            </div>
                        </div>
                    )}
                    <div className="single-therapist">
                        <h1 className="therapist-name">{user.Name}</h1>
                        <Image fileName={user.ImgPath} />
                        <p className="therapist-description">{user.Description}</p>
                        <p className="therapist-description">{user.Details}</p>
                        <button className="choose-button" onClick={chooseTherapist}>
                            Choose
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Therapist;
